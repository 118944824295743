<!-- Title Bar -->
<div class="title-bar">
    <div class="title">
        <h1 title="{{ 'NAVIGATION.SSH_KEYS' | translate }}">{{ "NAVIGATION.SSH_KEYS" | translate }}</h1>
    </div>
</div>
<!-- Tabs -->
<div class="tab-container with-router">
    <div class="tabset">
        <ul ngbNav #nav="ngbNav" class="justify-content-start nav nav-tabs">
            <li ngbNavItem id="aws-accounts-list-tab">
                <a ngbNavLink class="nav-link" title="{{ 'KEYS' | translate }}">{{ "KEYS" | translate }}</a>
                <ng-template ngbNavContent>
                    <router-outlet></router-outlet>
                </ng-template>
            </li>
            <li ngbNavItem id="aws-accounts-doc-tab">
                <a ngbNavLink class="nav-link" title="{{ 'IO_SERVER' | translate }}">{{ "IO_SERVER" | translate }}</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col mt-3">
                                <div class="details-form" *ngFor="let ioServer of ioServers">
                                    <!-- Hostname -->
                                    <div class="form-group row">
                                        <h5 class="col-12 mb-0">{{ "HOSTNAME" | translate }}</h5>
                                        <div class="col-12">
                                            {{ ioServer.dns_prefix }}{{ userAccount.tunnelers_host
                                            }}<button
                                                class="btn btn-sm btn-link"
                                                (click)="copyString(ioServer.dns_prefix + userAccount.tunnelers_host)"
                                                type="button"
                                                title="{{ 'COPY' | translate }}"
                                            >
                                                <fa-icon icon="clipboard" size="1x"></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                    <!-- SSH Public Key -->
                                    <div class="form-group row">
                                        <h5 class="col-12 mb-0">{{ "SSH_PUBLIC_KEY" | translate }}</h5>
                                        <div class="col-12">
                                            <a href="/api/tunnel_servers/{{ ioServer.id }}/public_key" download>
                                                {{ "DOWNLOAD" | translate }}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
